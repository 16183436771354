.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  padding: 50px 0;
  margin: 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  color: var(--theme-text-primary);
}

.header__title {
  margin: 0;
}

.info-message {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.info-message__title {
  margin: 0;
  color: var(--theme-text-primary);
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: '10px';
  gap: '16px';
  width: 60%;
}

.card__image-container {
  margin: 30px 0 0 20px;
  align-self: flex-start;
  display: flex;
}

.card__content-container {
  margin: 30px 25px 20px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  width: calc(100% - 25px);
}

@media (max-width: 1000px) {
  .card-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .card__image-container {
    align-self: center;
    margin: 20px 0 0 0;
  }

  .card__content-container {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
}

.card__content-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 30px 1fr;
  align-items: center;
  text-align: left;
}

.card__content-name {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.card__content-date {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  text-align: right;
}

.card__content-username {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: var(--theme-secondary-color);
}

.card__content-bio {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.card__content_stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  background-color: var(--theme-darker-background-color);
  padding: 20px;
  border-radius: 15px;
}

.card__content-stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card__content-stats-label {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: var(--theme-text-primary);
}

.card__content-stats-number {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--theme-text-primary);
}

.card__content-contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 40px);
  margin: 20px 0;
}

.card__content-contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  font-weight: 400;
  font-size: 1rem;
}

.card__content-contact-icon, .card__content-contact-text-no-available {
  color: var(--theme-text-secondary)
}

#location {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

#website {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

#twitter {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

#company {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

